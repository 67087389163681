<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-xl-3  col-sm-6 pl-4">
                    <b-skeleton v-if="!isload" width="60%"></b-skeleton>
                    <router-link class="h4 mb-1 link" style="color: #ECBD34" :to="{name:'ProfilCompetition',params:{'id':competition.id}}">{{competition.name}}</router-link>
                    <div class="media mb-2" >
                        <div class="avatar-sm mr-3">
                                           <span class="avatar-title rounded-circle bg-soft-warning text-warning font-size-22">
                                                                    {{ $store.state.comp_team.rank}}</span>
                        </div>
                        <div class="media-body d-flex mt-2">
                            <div class="mr-2">
                                <b-skeleton v-if="!isload" width="120px"></b-skeleton>
                                <router-link class="h6 text-truncate mb-1 link" :to="{name:'ProfilTeam',params:{'id':team.id}}">
                                    {{team.name}}
                                </router-link>

                                <h5 class="mb-0">

                                    {{$store.state.comp_team.score}} Pts</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 mb-2 col-sm-6">
                    <div id="timer" class="timer mb-2">
                        <!--  Timer Component  -->
                        <Timer
                                :passTime_p="passTime"
                        ></Timer>
                        <!--  End! Timer Component  -->

                    </div>
                </div>

                <div class="mb-2 col-sm-6" v-if="isload && partners.length">
                    <h6 class="mb-3">{{$t('partener.text')}}</h6>
                    <div class="row" v-for="partner in partners" :key="partner.id">
                        <b-avatar :src="partner.logo" rounded="sm" size="lg" :alt="partner.name" class=" mr-1 mb-1"></b-avatar>
                    </div>
                </div>
                <div class="col-xl-2 col-sm-6 ml-auto">
                    <button
                            @click="$router.push({ name: 'ScoreBoardCompetition', params: { id: $route.params.id}})"
                            class="btn btn-success mb-2 font-size-13 mr-2 float-right">
                        {{$t('competition.scoreboard')}}
                    </button>
                    <button
                            @click="$router.push({ name: 'statisticsCompetition', params: { id: $route.params.id}})"
                            class="btn btn-success  font-size-13 mr-2 float-right">
                        {{$t('profil_comp.statistics')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "@/assets/helpers/helper";
    import Timer from "./Timer";

    export default {
        name: "competitionChallengesHeader",
        components: {Timer},
        data(){
            return{
                isload:false,
                competition: {},
                distance:'',
                passTime:'',
                partners:[],
                team:{},
            }
        },
        created() {
            this.loadInfoCompetition()

        },
        // computed: {
        //     score () {
        //         return this.$store.state.team.score
        //     },
        //     rank () {
        //         console.log("rank",this.$store.state.team.rank)
        //         return
        //     }
        // },
        methods:{
            async loadInfoCompetition() {
                let item = await helper.getItem('me/competitions', this.$route.params.id);
                if(item) {
                  this.parteners = item.competition.partners;
                  this.competition = item.competition;
                  this.$emit('stateComp',this.competition.state)
                  this.team = item.team;
                  let score = item.team.score
                  let rank = item.team.rank
                  this.$store.commit('updateTeam', {rank, score})
                  this.distance = item.time.distance;
                  this.passTime = item.time.passTime;
                  this.isload = true
                }
            },
        }
    }
</script>

<style scoped>

</style>
