<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title text-center mb-3">{{$t('competition.notifications')}}</h4>

            <b-card-text v-if="isload">
                <simplebar style="max-height: 61vh;padding-right: 12px;padding-left: 12px;" data-simplebar-auto-hide="false" v-if="notifications.length">
                    <ul class="list-unstyled chat-list">

                        <li v-for="data of notifications"
                            :key="data.id">
                         <hr>

                            <div class="media mb-2">

                                <div class="media-body overflow-hidden">
                                    <h5 class="font-size-14 mb-1"
                                        :class=" { 'text-danger ': data.type == 'alert','text-warning ': data.type == 'warning',
                                   'text-info ': data.type == 'info' }">
                                        {{ data.title}}</h5>
                                    <p class=" mb-0">{{ data.description }}</p>
                                </div>
                                <div class="font-size-11">{{ data.time_diff}}
                                    <i class="ml-2" :class=" { 'text-danger fas fa-fire-alt': data.type == 'alert','text-warning fas fa-exclamation-triangle': data.type == 'warning',
                                   'text-info fas fa-info-circle': data.type == 'info' }"></i></div>

                            </div>

                        </li>
                    </ul>
                </simplebar>
                <span class="text-muted row" v-else>
                 <p class="m-auto">{{$t('validation.no_data')}}</p>
             </span>
            </b-card-text>
            <b-card-text   v-else>
                <simplebar style="max-height: 240px;padding-right: 12px;padding-left: 12px;">
                    <ul class="list-unstyled chat-list">

                        <li v-for="index in 4"
                            :key="index">
                            <hr>

                            <div class="media mb-2">
                                <div class="media-body overflow-hidden">
                                    <h5 class="font-size-14 mb-1">
                                        <b-skeleton width="50%" class="mb-2"></b-skeleton></h5>
                                    <p class=" mb-0">
                                        <b-skeleton width="100%" class="mb-2"></b-skeleton>
                                        <b-skeleton width="100%" class="mb-2"></b-skeleton>
                                        <b-skeleton width="60%" class="mb-2"></b-skeleton>
                                    </p>
                                </div>
                                <div class="font-size-11"> <b-skeleton width="20%" class="mb-2"></b-skeleton></div>

                            </div>

                        </li>
                    </ul>
                </simplebar>
            </b-card-text>

        </div>
    </div>
</template>

<script>

    import simplebar from "simplebar-vue";
    export default {
        name: "notificationsCompetition",
        components:{simplebar},
        props:['notifications','isload'],


    }
</script>

<style scoped>

</style>