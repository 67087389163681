<script>

import appConfig from "@/app.config";
import NotificationsCompetition from "../../components/notificationsCompetition";
import CompetitionChallengesHeader from "../../components/competitionChallengesHeader";
import Challenges from "../../components/Challenges";
import helper from "@/assets/helpers/helper";

export default {
  page: {
    title: "Competition",
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {
    Challenges,
    CompetitionChallengesHeader,
    NotificationsCompetition
  },
  name: 'Competition',
  data() {

    return {
      title: "Competition  Name",
      isloadnotif: false,
      state:'',
      notifications: [],

    };
  },
  watch: {
    "$store.state.team": function () {
      console.log("team_id", this.$store.state.team.id)
      var channel_team = this.$store.state.pusher_crisis.subscribe('private-competitions_' + this.$route.params.id + '_teams_' + this.$store.state.team.id);
      channel_team.bind('competition_notification_created', (data) => {
        console.log("new_notif_comp", data)
        this.newNotif(data)
      });
      channel_team.bind('competition_notification_updated', (data) => {
        console.log("edit_notif_comp", data)
        this.editNotif(data)
        // this.notifications[index]=data
      });
      channel_team.bind('competition_notification_deleted', (data) => {
        console.log("delete_notif_comp", parseInt(data.id))
        this.deleteNotif(parseInt(data.id))
      });
    }

  },


  created() {
    this.loadNotifications()
  },

  methods: {

    async loadNotifications() {
      this.notifications = await helper.getAll('competitions/' + this.$route.params.id + '/notifications');
      this.isloadnotif = true
    },
    newNotif(data){
      this.notifications.unshift(data)
    },
    editNotif(data){
      let index = this.notifications.findIndex(el => el.id === data.id)
      this.$set(this.notifications, index, data)
    },
    deleteNotif(id){
      let index = this.notifications.findIndex(el => el.id === id)
      if (index != -1) this.notifications.splice(index, 1);
    },
    setState(state){
      this.state = state
    }


  }
};
</script>
<template>
  <div>

    <competition-challenges-header v-on:stateComp="setState"/>

    <div class="row">
      <div class="col-xl-9 ">
       <challenges  type="competition" :state="state"
                   v-on:new_notif_comp="newNotif"
                   v-on:edit_notif_comp="editNotif"
                   v-on:delete_notif_comp="deleteNotif"/>
      </div>
      <div class="col-xl-3">
        <notifications-competition :notifications="notifications" :isload="isloadnotif"/>
      </div>
    </div>


  </div>
</template>
<style scoped>

</style>