<template>
    <div>
        <div class="my-auto" v-if="!text">
            <div class="day" >
                <span class="number">{{ days }}</span>
                <div class="format">{{ wordString.day }}</div>
            </div>
            <div class="hour">
                <span class="number">{{ hours }}</span>
                <div class="format">{{ wordString.hours }}</div>
            </div>
            <div class="min">
                <span class="number">{{ minutes }}</span>
                <div class="format">{{ wordString.minutes }}</div>
            </div>
            <div class="sec">
                <span class="number">{{ seconds }}</span>
                <div class="format">{{ wordString.seconds }}</div>
            </div>

        </div>
        <h6 class="text-muted text-danger  m-auto pt-4" v-if="text">{{text}}</h6>
    </div>


</template>

<script>
    export default {
        name: "Timer",
        props: ['passTime_p'],
        data: function () {
            return {
                timer: "",
                wordString: {
                    "day": "Day",
                    "hours": "Hours",
                    "minutes": "Minuts",
                    "seconds": "Seconds"
                },
                interval: "",
                days: 0,
                minutes: 0,
                hours: 0,
                seconds: 0,
                passTime: '',
                text:'',
                load:false,
            };
        },

        watch: {
            passTime_p: function () {
                this.load = true
                this.passTime = this.passTime_p;
                this.calcTime()
            }
        },
        mounted() {
            this.passTime = this.passTime_p;
            this.text = ''
             this.interval = setInterval(() => {
                 if(this.load)  this.calcTime();
            }, 1000);
        },
        methods: {
            calcTime() {
                if (this.passTime <= 0){
                    clearInterval(this.interval)
                    this.seconds = 0
                    this.text = 'Competition finished , You can show the scoreboard and statistics'
                }
                else if(this.passTime > 0){
                    this.days = Math.floor(this.passTime / 86400);
                    this.hours = Math.floor((this.passTime % 86400) / 3600);
                    this.minutes = Math.floor((this.passTime % 3600) / 60);
                    this.seconds = Math.floor(this.passTime % 60);
                    this.passTime = this.passTime - 1

                }

            }

        }
    }
</script>

<style scoped lang="scss">


    .timer {
        font-size: 20px;
        color: #fff;
        text-align:center;
        margin-top: 50px;

    .day, .hour, .min, .sec {
        font-size: 22px;
        display: inline-block;
        font-weight: 300;
        text-align: center;
        margin: 0 5px;
    .format {
        font-weight: 300;
        font-size: 14px;
    //@include margin-start(5);
    //display: inline-block;
        opacity: 0.8;
        width: 60px;
    }
    }
    .number{

        padding: 0 5px;
        border-radius: 5px;
        display: inline-block;
        width: 60px;
        text-align: center;
    }

    }
</style>